<template>
  <div>
    <AppHeader title="预约管理"/>
    <Panel class="tabs search-panel" :title="false">
      <div slot="content">
        <div class="line">
          <input class="line-input" id="eqName" @click="showEquipments" v-model="equipment" @keydown="preventDefault" placeholder="仪器名称"/>
          <input class="line-input mt10" id="beginAt" @click="showPicker('beginAt')" :value="dateFormat(beginAt)" @keydown="preventDefault" placeholder="开始时间"/>
          <input class="line-input mt10" id="endAt" @click="showPicker('endAt')" :value="dateFormat(endAt)" @keydown="preventDefault" placeholder="结束时间"/>
          <div class="flex-dir-row mt10">
            <div class="flex-item-auto">
              <input class="line-input" id="userName" v-model="userName" placeholder="实验人"/>
            </div>
            <div class="btn btn-small green ml5" @click="reset">重设</div>
            <div class="btn btn-small green ml5" @click="initBookings">查询</div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel class="tabs mt15" :title="false">
      <div slot="content" class="status-content" v-infinite-scroll="loadEvents"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(b,idx) of bookings" style="position: relative;overflow: hidden">
          <div :class="`booking-status ${b.statusClass}`">{{ b.statusText }}</div>
          <div class="flex-dir-row">
              <div class="line-label">实验编号：</div>
              {{ b.code }}
          </div>
          <div class="flex-dir-row">
              <div class="line-label">实验人：</div>
              {{ b.user.realName }}
          </div>

          <div class="flex-dir-row">
              <div class="line-label">预约时间：</div>
              {{ beginEnd(b.beginAt, b.endAt) }}
          </div>

          <div class="flex-dir-row">
            <div class="flex-dir-row flex-item-auto">
              <div class="line-label">仪器名称：</div>
              {{ b.equipment.name }}
            </div>
            <div class="flex-dir-row">
              <div v-if="b.cancelable" class="btn btn-small green" @click="cancelBooking(b.id)">取消</div>
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="bookings.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-datetime-picker
        v-model="dateVal"
        type="datetime"
        @confirm="handleConfirm"
        ref="picker"
    >
    </mt-datetime-picker>

    <mt-popup
        v-model="equipmentsVisible"
        position="bottom"
    >
      <mt-picker :slots="equipmentSlots" @change="onEquipmentChange" value-key="text"
                 v-if="equipmentsVisible"></mt-picker>
    </mt-popup>

  </div>
</template>

<style lang="scss">
.booking-status {
  left: 0;
  top: 0;
  position: absolute;
  font-size: 10px;
  text-align: center;
  color: #fff;
  padding: 0 10px;
  opacity: .9;
  width: 50px;
  height: 20px;
  line-height: 20px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);

  &.booking {
    background: #3b5998;
  }

  &.doing {
    background: #8CC152;
  }

  &.cancel {
    background: #434A54;
  }

  &.finish {
    background: #3b5998;
  }
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, DatetimePicker, Picker, MessageBox} from 'mint-ui';
import {mapState} from 'vuex'

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    DatetimePicker,
    Picker
  },
  data() {
    return {
      page: 1,
      bookings: [],
      loading: false,
      noMore: false,
      beginAt: null,
      endAt: null,
      equipment: null,
      equipmentId: null,
      userName: null,

      dateVal: new Date(),
      pickerField: '',

      equipmentsVisible: false,
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    equipmentSlots(state) {
      return [{values: state.equipments}]
    }
  }),
  methods: {
    beginEnd(beginAt, endAt) {
      let beginYMD = this.$moment(beginAt).format('YYYY.MM.DD')
      let result = this.$moment(beginAt).format('YYYY.MM.DD HH:mm')
      if (endAt) {
        let endYMD = this.$moment(endAt).format('YYYY.MM.DD')
        if (endYMD == beginYMD) {
          result += ("-" + this.$moment(endAt).format('HH:mm'))
        } else {
          result += ("-" + this.$moment(endAt).format('YYYY.MM.DD HH:mm'))
        }
      } else {
        result += "-"
      }
      return result
    },
    onEquipmentChange(picker, values) {
      this.equipment = values[0].text
      this.equipmentId = values[0].id
    },
    showEquipments() {
      this.equipmentsVisible = true
    },
    showPicker(pickerField) {
      this.dateVal = new Date()
      this.pickerField = pickerField
      this.$refs.picker.open()
    },
    handleConfirm(value) {
      if (this.pickerField === 'beginAt') {
        this.beginAt = this.$moment(value).valueOf()
      } else {
        this.endAt = this.$moment(value).valueOf()
      }
    },
    reset() {
      this.page = 1
      this.loading = false
      this.beginAt = null
      this.endAt = null
      this.userName = ''
      this.equipment = ''
      this.equipmentId = ''
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YY-MM-DD HH:mm')
      }
      return ''
    },
    initBookings() {
      this.bookings = []
      this.page = 1
      this.noMore = false
      this.loadEvents()
    },
    loadEvents() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      data.append('orderByBeginAtDesc', 'true')
      this.equipmentId && data.append('equipmentId', this.equipmentId)
      this.beginAt && data.append('beginAt', `${this.beginAt}`)
      this.endAt && data.append('endAt', `${this.endAt}`)
      this.userName && data.append('userName', `${this.userName}`)
      data.append('orderByBeginAtDesc', 'true')
      //?orderByBeginAtDesc=true&page=1&limit=10&equipmentId=&userId=&beginAt=&endAt=&deptId=&advisorId=&status=
      that.$http.post(`/portal/booking/query`, data).then(res => {
        if (res.data && res.data.length > 0) {
          const bookings = res.data.map(b => {
            if (b.status === 0) {
              b.statusText = '已预约'
              b.statusClass = 'booking'
              b.cancelable = true
            } else if (b.status === 3) {
              b.statusText = '实验中'
              b.statusClass = 'doing'
            } else if (b.status === 5) {
              b.statusText = '被撤销'
              b.statusClass = 'cancel'
            } else if (b.status === 6) {
              b.statusText = '已完成'
              b.statusClass = 'finish'
            }
            return b
          })
          that.bookings = that.bookings.concat(bookings)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    preventDefault(e) {
      e.preventDefault()
    },
    cancelBooking(id){
      const that= this
      MessageBox.confirm('确定取消预约?').then(action => {
        that.$http.post(`/portal/booking/cancel?id=${id}`).then(res=>{
          that.initBookings()
        })
      })
    }
  },
  mounted() {
    this.initBookings()
  }
}
</script>
